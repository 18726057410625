<template>
    <div class="sidebar-content flex grow shrink-0 py-5 pr-2 bg-bluee-100 " id="sidebar_content"
        style="background:#20B2FF">
        <div class="bg-emerald-900 text-slate-50 scrollable-y-hover grow shrink-0 flex pl-2 lg:pl-5 pr-1 lg:pr-3"
            data-scrollable="true" data-scrollable-dependencies="#sidebar_header" data-scrollable-height="auto"
            data-scrollable-offset="0px" data-scrollable-wrappers="#sidebar_content" id="sidebar_scrollable">
            <div class="menu flex flex-col grow gap-0.5" data-menu="true" data-menu-accordion-expand-all="false"
                id="sidebar_menu">

                <div class="menu-item" data-menu-item-toggle="accordion" data-menu-item-trigger="click">
                    <a href='/dashboard' tabindex="0">
                        <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                            tabindex="0">

                            <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                                <i class="ki-filled ki-element-11  text-lg"></i>
                            </span>

                            <span
                                class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Dashboard
                            </span>

                        </div>
                    </a>    
                </div>

                <div v-if="isInMenus('Users')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-profile-circle text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Users
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>
                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/account') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Department')"
                            :class="['menu-item', isActive('/account/department') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/account/department' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Department </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Position')"
                            :class="['menu-item', isActive('/account/position') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/account/position' tabindex="1">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Position </span>
                            </a>
                        </div>

                        <div v-if="isInSection('User Right')"
                            :class="['menu-item', isActive('/account/right') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/account/right' tabindex="1">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    User Right </span>
                            </a>
                        </div>

                        <div v-if="isInSection('User List')"
                            :class="['menu-item', isActive('/account/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/account/list' tabindex="1">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    User List </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="isInMenus('Orders')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-badge text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Orders
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/customer') ? 'show' : ''
                    ]">

                        <div v-if="isInSection('Customer List')"
                            :class="['menu-item', isActive('/customer/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/customer/list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Customer List </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Order List')"
                            :class="['menu-item', isActive('/customer/order_list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/customer/order_list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Order List </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="isInMenus('Product')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-basket-ok text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Product
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div v-if="isInSection('Product List')" :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/product') ? 'show' : ''
                    ]">
                        <div :class="['menu-item', isActive('/product/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/product/list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    List </span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/product') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Repackage')"
                            :class="['menu-item', isActive('/product/repackage') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/product/repackage' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Repackage </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="isInMenus('Supplier')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-badge text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Supplier
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/supplier') ? 'show' : ''
                    ]">

                        <div v-if="isInSection('Supplier List')"
                            :class="['menu-item', isActive('/supplier/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/supplier/list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    List </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="isInMenus('Inventory')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-badge text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Inventory
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/inventory') ? 'show' : ''
                    ]">

                        <div v-if="isInSection('Item Ledger')"
                            :class="['menu-item', isActive('/inventory/item_ledger_list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/inventory/item_ledger_list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Item Ledger </span>
                            </a>
                        </div>



                        <div v-if="isInSection('Item Received List')"
                            :class="['menu-item', isActive('/inventory/item_received_list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/inventory/item_received_list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Item Recieved List </span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/inventory') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Inventory Count List')"
                            :class="['menu-item', isActive('/inventory/inventory_count_list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/inventory/inventory_count_list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Inventory Count List </span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/inventory') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/inventory/item_adjustment_list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/inventory/item_adjustment_list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Item Adjustment List </span>
                            </a>
                        </div>
                    </div>


                </div>

                <div v-if="isInMenus('Purchase Order')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-badge text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Purchase
                            Order </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/purchase') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/purchase/list' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    List </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="isInMenus('Purchase Order')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-badge text-lg"></i>
                        </span>
                        <span class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Reports</span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/stock_levels' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300"> Stock Levels </span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/inventory_valuation' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Inventory Valuation</span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/inventory_turn_over' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Inventory Turnover</span>
                            </a>
                        </div>
                    </div>


                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/sales_and_purchase' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Sales and Purchase</span>
                            </a>
                        </div>
                    </div>


                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/order_status' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Order Status</span>
                            </a>
                        </div>
                    </div>

                    
                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/stock_location' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Stock Location</span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/product_category' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Product Category</span>
                            </a>
                        </div>
                    </div>



                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/low_stock' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Low Stock</span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/item_adjustment' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Item Adjustment </span>
                            </a>
                        </div>
                    </div>

                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/report') ? 'show' : '']">
                        <div v-if="isInSection('Item Adjustment List')"
                            :class="['menu-item', isActive('/purchase/list') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/report/historical_inventory' tabindex="0">
                                <span class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Historical Inventory</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="isInMenus('Settings')" class="menu-item" data-menu-item-toggle="accordion"
                    data-menu-item-trigger="click">
                    <div class="menu-link flex items-center grow cursor-pointer border border-transparent gap-[10px] pl-[10px] pr-[10px] py-[6px]"
                        tabindex="0">
                        <span class="menu-icon items-start text-gray-500 dark:text-gray-400 w-[20px]">
                            <i class="ki-filled ki-setting-2 text-lg"></i>
                        </span>
                        <span
                            class="menu-title text-sm font-semibold color_me menu-item-active:text-success menu-link-hover:!text-blue-300">Settings
                        </span>
                        <span class="menu-arrow text-gray-400 w-[20px] shrink-0 justify-end ml-1 mr-[-10px]">
                            <i class="ki-filled ki-plus text-2xs menu-item-show:hidden text-black"></i>
                            <i class="ki-filled ki-minus text-2xs hidden menu-item-show:inline-flex text-black"></i>
                        </span>
                    </div>
                    <div :class="['menu-accordion  gap-0.5 pl-[10px] relative before:absolute before:left-[20px] before:top-0 before:bottom-0 before:border-l before:border-gray-200',
                        isActive('/settings') ? 'show' : ''
                    ]">
                        <div v-if="isInSection('Menu')"
                            :class="['menu-item', isActive('/settings/menu') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/menu' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Menu </span>
                            </a>
                        </div>
                        <div v-if="isInSection('Category')"
                            :class="['menu-item', isActive('/settings/category') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/category' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Category </span>
                            </a>
                        </div>
                        <div v-if="isInSection('Season')"
                            :class="['menu-item', isActive('/settings/season') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/sesson' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Season </span>
                            </a>
                        </div>
                        <div v-if="isInSection('Unit of Measure')"
                            :class="['menu-item', isActive('/settings/uom') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/uom' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Unit Of Measure </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Payment')"
                            :class="['menu-item', isActive('/settings/payment') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/payment' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Payment </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Rack')"
                            :class="['menu-item', isActive('/settings/rack') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/rack' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Rack </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Warehouse')"
                            :class="['menu-item', isActive('/settings/warehouse') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/warehouse' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Warehouse </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Color')"
                            :class="['menu-item', isActive('/settings/color') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/color' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Color </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Classification')"
                            :class="['menu-item', isActive('/settings/classification') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/classification' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">
                                    Classification </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Country')"
                            :class="['menu-item', isActive('/settings/country') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/country' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Country
                                </span>
                            </a>
                        </div>

                        <div v-if="isInSection('Company Info')"
                            :class="['menu-item', isActive('/settings/company') ? 'rounded-lg bg-white' : '']">
                            <a class="menu-link border border-transparent items-center grow menu-item-active:bg-secondary-active dark:menu-item-active:bg-coal-300 dark:menu-item-active:border-gray-100 menu-item-active:rounded-lg hover:bg-secondary-active dark:hover:bg-coal-300 dark:hover:border-gray-100 hover:rounded-lg gap-[14px] pl-[10px] pr-[10px] py-[8px]"
                                href='/settings/company' tabindex="0">
                                <span
                                    class="menu-bullet flex w-[6px] relative before:absolute before:top-0 before:size-[6px] before:rounded-full before:-translate-x-1/2 before:-translate-y-1/2 menu-item-active:before:bg-primary menu-item-hover:before:bg-primary"></span>
                                <span
                                    class="menu-title text-2sm font-medium color_me menu-item-active:text-success menu-item-active:font-semibold menu-link-hover:!text-blue-300">Company
                                    Info </span>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
export default {
    name: 'SidebarPage',
    computed: {
        currentRoute() {
            return this.$route.path;
        }
    },
    mounted() {
        this.getAccessMenu()
    },
    methods: {
        toggleSidebar() {
            console.log("Ssdfsdfsdf");
            // Logic to toggle sidebar
        },
        isActive(route) {
            return this.currentRoute.startsWith(route);
        },
        isInMenus(item) {
            return this.menus.includes(item);
        },
        isInSection(item) {
            return this.section.includes(item);
        },
        async getAccessMenu() {

            /*const storedMenus = localStorage.getItem('menus');
            const storedSection = localStorage.getItem('section');

            if (storedMenus && storedSection) {
                // Parse the stored data
                this.menus = JSON.parse(storedMenus);
                this.section = JSON.parse(storedSection);
            } else {
                try {
                    const response = await apiClient.get(`/user_right/get/access_menu`);

                    if (response.data.status === true) {
                        // Store the retrieved data in local storage
                        this.menus = response.data.data.menu;
                        this.section = response.data.data.section;

                        localStorage.setItem('menus', JSON.stringify(this.menus));
                        localStorage.setItem('section', JSON.stringify(this.section));
                    } else {
                        this.$router.push('/');
                    }
                } catch (error) {
                    console.error('Error fetching menu access:', error);
                }
            }*/
            try {

                const response = await apiClient.get(`/user_right/get/access_menu`);

                if (response.data.status == true) {
                    this.menus = response.data.data.menu;
                    this.section = response.data.data.section;
                } else {
                    this.$router.push('/');
                }

            } catch (error) {
                console.error('Error submitting form:', error.response);

            }

        },
    },
    data() {
        return {
            menus: [],
            section: []
        }
    }
}
</script>
<style>
.color_me {
    color: rgb(4 97 46);
}
</style>
