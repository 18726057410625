<template>
    <AccessControl :menuId="3" :menuSectionId="7" nameOfPage='Product List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                           Stock Level Report
                        </h1>
                    </div>
                   
                </div>

                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-6">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>                           

                                <label class="input input-sm" style="margin-left:10px;">
                                    Warehouse:
                                    <select ref="ware_house_id" v-model="ware"
                                        class="bg-gray-50 border ">
                                        <option value="0" selected>- Select -</option>
                                        <option v-for="warehouse in ware_house_list" :key="warehouse.warehouse_id"
                                            :value="warehouse.warehouse_id">
                                            {{ warehouse.warehouse_name }}
                                        </option>
                                    </select>
                                </label>


                                <div>
                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                       

                    </vue-good-table>
                    

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import apiClient_multi from '@/services/apigen_multipart'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';
import { useRouter, useRoute } from 'vue-router';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    }, async mounted() {        
        let ware_id = this.$route.query.ware_id; // Accessing route params via 'this'
        let code = this.$route.query.code; // Accessing route params via 'this'
        
        if(code!=""){
            this.searchTerm = code;
        }       
        await this.WarehouseList(ware_id);
        //fetchData();
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'product_id', type: 'number', sortable: true },
            { label: 'Product Code', field: 'product_code', sortable: true },
            { label: 'Product Name', field: 'product_name', sortable: true },
          
       
          
            { label: 'Quantity on Hand', field: 'running_balance', sortable: true },
            { label: 'Re Order', field: 'low_qty', sortable: true },
            { label: 'location', field: 'warehouse_name', sortable: true },
          

           
        ]);
        const route = useRoute()    // Access the current route    
        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const dateFrom = ref('');
        const dateTo = ref('');
        const cat = ref(route.query.cat_id ?? 0)
        const ware = ref('0')

        const sortField = ref('product_name');
        const sortType = ref('asc');

      

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value
        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value,
                    cat: cat.value,
                    ware:ware.value,
                };

                const { data } = await apiClient.get('/reports/low_stock', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo, cat,ware], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo,
            cat,
            ware
        };
    },
    methods: {

        async generate_report() {

         
            let searchTerm = this.searchTerm;
            let cat = this.cat;
            let ware = this.ware;

         
            this.export_loading = true; // Set loading state to true
            const params = {
                search: searchTerm,
                ware:ware
            };

            try {
                const response = await apiClient.get(`/exports/low_stock`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'low_stock.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading report:', error);
            }
        },


        async WarehouseList(ware_id) {
            try {
                const response = await apiClient.get('/warehouse/list/all');
                if (response.data.status == true) {
                    this.ware_house_list = response.data.data;
                    this.ware = ware_id;
                    //$this.$refs.ware_house_id.addEventListener('change', fetchData);
                    
                }
            } catch (error) {
                console.error('Error submitting form:');

            }
        },



    },
    data() {
        return {
            showModal: false,
            showModal3: false,
            form: {
                category_id: 0
            },
            showModal2: false,
            currentItem: [],
            classification_list: [],
            category_list: [],
            sub_category_list: [],
            ware_house_list: [],
            rack_list: [],
            season_list: [],
            color_list: [],
            uom_list: [],
            edit_first: 0,
            product_images: [],
            image_directory: "",
            showPreview: false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            export_loading: false
        };
    },
}
</script>


<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Flexible columns */
    gap: 10px;
    /* Spacing between tiles */
    overflow-x: auto;
    /* Allow horizontal scroll if needed */
}

.tile {
    position: relative;
    width: 100%;
    padding-top: 75%;
    /* Aspect ratio of the tile */
    overflow: hidden;
    background-color: #f0f0f0;
    /* Background color for tiles */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Align the button at the bottom */
    border: 2px solid #333;
    /* Add border: width, style, and color */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add a shadow for a raised effect */
}

.tile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Cover the tile area */
}

.remove-button {
    position: relative;
    background-color: #ff4d4d;
    /* Red button */
    color: white;
    border: none;
    padding: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.remove-button:hover {
    opacity: 1;
    /* Make button fully visible on hover */
}
</style>